import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import Input from './Input';
import Button from './Button';
import { ImgDropdown, ImgDropup } from '../../pictures/SVG';
import Btn from './Btn';

export default function Number({ label, suffix, value, setValue, min = 0, max = 100, disabled }) {
    let ref = useRef();
    let [width, setWidth] = useState(0);
    useLayoutEffect(() => {
        let width = ref.current.getBoundingClientRect().width;
        setWidth(width+10)
    },[value])
    return <div className="Number">
        <div className="HIDDEN">
            <div className="num" ref={ref}>
                {value}
            </div>
        </div>
        {label ? <label>{label}</label> : null}
        <div className="innerNumber">
            <div className="leftNumber">
                <div className="inputBit" style={{width}}>
                    <Input
                        type="number"
                        value={value}
                        setValue={n => {
                            setValue(n ? n < min ? min : n > max ? max : n : min);
                        }}
                        noUnderline
                    />
                </div>
            </div>
            <div className="rightNumber">
                <div className="buttonsBit">
                    <Btn className="numButton" height="0.5rem" onClick={()=>setValue(v=>v+1)} disabled={disabled || value >= max}>
                        <ImgDropup className={disabled || value >= max? "GREY" : "BLUE"}/>
                    </Btn>
                    <Btn className="numButton" height="0.5rem" onClick={()=>setValue(v=>v-1)} disabled={disabled || value <= min}>
                        <ImgDropdown  className={disabled || value <= min ? "GREY" : "BLUE"}/>
                    </Btn>
                </div>
                {suffix ? <div className="suffix">{suffix}</div>:null}
            </div>
        </div>
    </div>
}