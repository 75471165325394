import React, { useContext, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { ToastContext, ModalContext, AccountContext, useDynamic, useAreYouSure, useSound, ResponsiveContext } from '../../hooks';
import UserPanel from '../user/UserPanel';
import Dropdown from '../controls/Dropdown';
import P from '../controls/P';
import Toast from '../modal/Toast';
import ShareThought from '../forms/ShareThought';
import Comments from './Comments';

import axios from 'axios';

import { ImgDropdown } from '../../pictures/SVG';
import StartPoll from '../forms/StartPoll';
import Btn from '../controls/Btn';
import Login from '../forms/Login';
import Signup from '../forms/Signup';
import { dateString } from '../../functions';
import Shrinker from '../controls/Shrinker';

export default function PollPost({ post }) {
    let { user, checkPermissions } = useContext(AccountContext);
    let Dynamic = useDynamic('post', post._id);
    let SetDeleted, SetLoading, SetPost;
    if (Dynamic) {
        SetDeleted = Dynamic.SetDeleted;
        SetLoading = Dynamic.SetLoading;
        SetPost = Dynamic.SetPost;
    }
    let Toasts = useContext(ToastContext);
    let Modals = useContext(ModalContext);
    let areYouSure = useAreYouSure();

    let [answers, setAnswers] = useState(post.answers);
    let [showResults, setShowResults] = useState(post.vote >= 0 ? true : false); 

    let [vote, setVote] = useState(post.vote);

    let me = user && (user.username == post.username);

    let login = () => Modals.create(localStorage.getItem('happy') ? Login : Signup, { feature: true });

    async function deletePost() {
        if ((me || checkPermissions('Remove posts')) && await areYouSure("Are you sure you want to delete this post?")) {
            SetLoading(true);
            let {data} = await axios.post('/api/delete-post', { post, user });
            if (data) {
                SetDeleted(true);
            } else {
                Toasts.create(Toast, {preset:'server error'});
            }
            SetLoading(false);
        }
    }

    async function editPost() {
        Modals.create(StartPoll, {
            post,
            callback: async post => {
                let {data} = await axios.post('/api/edit-post', { post, user });
                if (data) {
                    SetPost(data);
                    return true;
                } else {
                    return false;
                }
            }
        })
    }

    let dropdownOptions = []

    if (me || checkPermissions('Edit posts')) dropdownOptions.push({title: 'Edit post', callback:editPost });
    if (me || checkPermissions('Remove posts')) dropdownOptions.push({ title: 'Delete post', callback: deletePost });
    
    if (user) {
        dropdownOptions.push({
            title: 'Stop notifications', callback: async () => {
            await axios.post('/api/stop-notifications', { user, post: {_id:post._id} });
        } });
    }

    let votingOver = post.deathday < new Date().getTime();
    let totalVotes = answers.reduce((a, b) => a + b.votes, 0) + (vote >= 0 ? 1 : 0);

    return <div className="Poll Post" >
        <div className="postLeft">
            <UserPanel post={post}/>
        </div>
        <div className="postRight">
            <div className="title">
                <div className="flex1">{post.question}</div>
                {dropdownOptions.length>0?<Dropdown
                    options={dropdownOptions}
                    btnProps={{className:"dropArrow"}}
                >
                    <ImgDropdown className="DARKGREY"/>
                </Dropdown>:null}
            </div>
            <div className="body">
                <div>
                    {totalVotes == 1 ? "1 user has voted" : `${totalVotes} users have voted`}
                </div>
                <div className="answers">
                    {answers.map(({ answer, votes }, i) => <div key={i}>
                        <Answer
                            i={i}
                            post={post}
                            answer={answer}
                            vote={vote}
                            setVote={setVote}
                            votes={votes}
                            showResults={showResults}
                            setShowResults={setShowResults}
                            totalVotes={totalVotes}
                            user={user}
                            login={login}
                            votingOver={votingOver}
                        />
                    </div>)}
                </div>
                <div>
                    {!votingOver ? <Shrinker expanded={!showResults && totalVotes > 0}>
                        <div className="mb">
                            <Btn onClick={()=>setShowResults(true)}>Show results</Btn>
                        </div>
                    </Shrinker>:null}
                    <div className="smallGreyItalic">
                        {votingOver ? "Voting has concluded." : "Voting ends on "+dateString(post.deathday)}
                    </div>
                </div>
            </div>
            <Comments post={post}/>
        </div>
    </div>
}

function Answer({ i, votingOver, login, user, post, answer, votes, showResults, setShowResults, totalVotes, vote, setVote }) {
    if (votingOver) showResults = true;

    let bar = showResults ? ((vote == i ? votes + 1 : votes) / totalVotes) : 0;
    if (!bar || isNaN(bar)) bar = 0;

    let ref = useRef();

    let { innerWidth } = useContext(ResponsiveContext);
    let [width, setWidth] = useState(0);

    useEffect(() => {
        window.addEventListener('load', resize);
        return () => window.removeEventListener('load', resize);
    }, []);

    function resize() {
        let width = ref.current.getBoundingClientRect().width;
        setWidth(width);
    }

    useLayoutEffect(resize,[innerWidth])

    let render = <div className="answer">
        <div className="innerAnswer" ref={ref}>
            {answer}
        </div>
        <div className="bar" style={{ width: `${bar*100}%` }}>
            <div className="innerBar" >
                <div className="innerAnswer" style={{width}}>
                    {answer}
                </div>
            </div>
        </div>
    </div>

    if (votingOver) {
        return render;
    } else {
        return <Btn onClick={async () => {
            if (!user) {
                login();
            } else {
                setShowResults(true);
                setVote(i);
                await axios.post('/api/vote-on-poll',{post:{_id:post._id}, answer:i, user})
            }
        }}>
        {render}
    </Btn>
    }
}