import React, { useState,useRef, useEffect } from 'react';
import Button from '../controls/Button';

import Input from '../controls/Input';

import { ImgDropdown, ImgDropup } from '../../pictures/SVG';
import { useChange } from '../../hooks';

export default function Pounds({ pounds, suffix, setPounds, label='Choose your monthly donation amount', symbol='£', disabled, monthly=true, min, max, onChange }) {
    let ref = useRef();
    let ignore = useRef(false);
    let [width, setWidth] = useState();

    let [value, setValue] = useState(pounds+'');

    useEffect(() => {
        if (!ignore.current) {
            let pounds = '';
            let nums = "0123456789";
            for (let i = 0; i < value.length; i++){
                if (nums.indexOf(value[i]) >= 0) {
                    pounds += value[i];
                }
            }
            pounds = parseInt(pounds);
            if (isNaN(pounds) || pounds < 0) pounds = 0;
            if (min !== undefined && pounds < min) pounds = min;
            if (max !== undefined && pounds > max) pounds = max;
            setPounds(pounds);
            ignore.current = true;
            setValue(pounds + '');
            ignore.current = false;
        }
    },[value])

    useEffect(() => {
        setWidth(ref.current.getBoundingClientRect().width + 6);
    }, [value, symbol])

    useChange(() => {
        if (onChange) onChange();
    },[pounds])

    return <div className="Pounds">
        <label>{label}</label>
        <div className="measureContainer">
            <div className="measure" ref={ref}>
                {symbol}{value}
            </div>
        </div>
        <div className="pounds">
            <div className="poundsLeft">
                <div className="innerLeft">
                    <div className="flex1"/>
                    <div className="resizer" style={{ width }}>
                        <Input value={value} setValue={setValue} disabled={disabled} icon={symbol} />
                    </div>
                </div>
                {monthly?'Per month':null}
            </div>
            <div className="poundsRight">
                <div className="buttons">
                    <Button className="poundButton" height="0.5rem" onClick={()=>setValue((pounds+1)+'')} disabled={disabled}>
                        <ImgDropup className="BLUE"/>
                    </Button>
                    <Button className="poundButton" height="0.5rem" onClick={()=>setValue((pounds-1)+'')} disabled={disabled || pounds <= 0}>
                        <ImgDropdown  className={pounds <= 0 ? "GREY" : "BLUE"}/>
                    </Button>
                </div>
                {suffix ? <div className="suffix">{suffix}</div> : null}
            </div>
        </div>
        
    </div>
}