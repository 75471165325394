import React, { useContext, useEffect, useState } from 'react';
import { AccountContext, useAsync, NotificationsContext, useTitle, CentreForceRenderContext, CentreKeyContext } from '../../hooks';
import axios from 'axios';

import Spinner from '../Logo/Spinner';
import Post from '../posts/Post'

export default function PostSingle({ url, page }) {
    let { user } = useContext(AccountContext);
    let [post, setPost] = useState();
    let { getNotifications, setNotifications } = useContext(NotificationsContext);

    let centreKey = useContext(CentreKeyContext);



    let { setDocumentTitle } = useTitle();

    useAsync(async () => {
        let { data } = await axios.post('/api/post', { url, user });
        setPost(data.post);
        console.log(data.post);
    }, [user])

    useEffect(() => {
        if (post) {
            setDocumentTitle(`${post.type == 'Ambigram' || post.type == 'Entry' || post.type == 'ChallengeEntry' || post.type == 'DuelEntry' ? post.word : post.type == 'Challenge' ? `${post.word} challenge` : post.type == 'Blog' ? post.title : post.type == 'Poll' ? post.question : 'Post'} - $`);
            return () => setDocumentTitle('$');
        } else {
            setDocumentTitle('$')
        }
    }, [post, centreKey])


    useAsync(async () => {
        if (user&&post) {
            let notifications = getNotifications();
            let i = notifications.findIndex(n => n.url == post.url);
            if (i >= 0) {
                axios.post('/api/seen', { url: post.url, user });
                notifications.splice(i, 1);
                setNotifications(notifications.slice());
            }
        }
    }, [user, post])

    return <div className="PostSingle Panel centrepanel">
        {post ? <Post post={post} single={true} page={page} /> : <div className="centreFlex">
            <Spinner/>
        </div>}
    </div>
}