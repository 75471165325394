import React, { useState, useContext } from 'react';
import { AccountContext, useAsync, ModalContext, useAreYouSure } from '../../../hooks';
import VerificationMessage from '../../forms/VerificationMessage';
import CheckoutModal from '../../checkout/CheckoutModal';
import EditSub from '../../checkout/EditSub';
import Button from '../../controls/Button';
import Login from '../../forms/Login';
import Signup from '../../forms/Signup';
import axios from 'axios';
import Expand from '../../controls/Expand';

function Instructions({user}) {
    return <ul>
        {/* <li>
            <Expand label="Ad space!">
                <p>Some visitors to the site might want to commission a custom ambigram, rather than make one themselves.
                    There is a page for such visitors <a href={'/adverts'} target="_blank" rel="noopener noreferrer external">here</a>, where
                    ambigram designers such as yourself may advertise their services.</p>
                {user ? <p><a href={'/advert/' + user.username.toLowerCase()} target="_blank" rel="noopener noreferrer external">Click here</a> to set up your advert!</p> : null}
            </Expand>
        </li> */}
        <li>
            <Expand label="Downloadable animations of your ambigrams!">
                <p>There may come an occasion when you want to share your ambigrams on a site other than this (I won't judge).</p>
                <p>In such an occasion, you might feel frustrated that no other website offers the same high quality animation features that this one does.</p>
                <p>By clicking on the drop down icon at the top right of their ambigram, donors can download an animated video of it, which 
                    can then be used on other social media so that their Instagram followers can see an impressive rotating design, rather than a boring static one.</p>
            </Expand>
        </li>
        <li>
            <Expand label="An extra submission to the monthly contest!">
                <p>Standard users of this site may only submit 2 entries to each contest.</p>
                <p>Donors however, may submit 3. Perhaps this is unfair, but money talks. 💵💵💵</p>
            </Expand>
        </li>
        <li>
            <Expand label="An animated profile picture!">
                <p>For a user on an ambigram social media website, it would make sense that you might use an ambigram as your profile picture.</p>
                <p>However, for the hoi polloi this would not be satisfactory, as their profile pictures stay static, and do not animate like the
                    other ambigrams on this site.</p>
                <p>For the exclusive and well appreciated donors however, you have the option to add animations to your profile pictures too - so
                    that they may also spin around upon a mouse click, and everyone will think you are very cool.</p>
                <p>You can find this feature in your profile settings.</p>
            </Expand>
        </li>
        <li>
            <Expand label="A little crown next to your name!">
                <p>At ambigr.am, we understand how important it is for nobility such as yourself to flaunt your sovereignity over the common folk.</p>
                <p>It is for this reason, that all donors to the site will have a little crown displayed next to their names, so that the unworthy rabble
                    and riffraff cannot help but acknowledge your superior stature.</p>
            </Expand>
        </li>
    </ul>
}


export default function Subscription({ url }) {
    let { user, setUser, subbed } = useContext(AccountContext);
    let Modals = useContext(ModalContext);
    let areYouSure = useAreYouSure();

    let [subscription, setSubscription] = useState(user ? user.sub : null);

    let login = () => Modals.create(localStorage.getItem('happy') ? Login : Signup, { feature: true });
    let callback = (f) => {
        if (user) {
            if (user.verified) {
                return f
            } else return () => Modals.create(VerificationMessage,{feature:true})
        } else return login;
    }

    useAsync(async () => {
        setSubscription(user ? user.sub : null);
    }, [user])

    return <div className="Subscription Panel centrepanel">
        {
            !subscription || subscription.pounds <= 0 ? <div>
                <h1>Activate your premium account</h1>
                <h4>With a premium account, you get access to some neat features!</h4>
                <p>This site is run by 1 person out of love, and there are a lot of costs involved.</p>
                <p>If you appreciate the site, you may make a monthly donation of as little as £1 to enjoy some extra features!</p>
                <p>You will get:</p>
                <Instructions user={user}/>
                <Button
                    onClick={callback(() => {
                        Modals.create(CheckoutModal, {
                            callback: async pounds => {
                                user.sub = { pounds, expires: new Date().getTime() + 1000 * 60 * 60 * 24 * 32 };
                                setSubscription(user.sub);
                                setUser(JSON.parse(JSON.stringify(user)));
                            }
                        })
                    })}
                >
                    Donate!
                </Button>
            </div> : <div>
                {subscription.expires > new Date().getTime() ? <>
                    <h1>Thank you for your donation!</h1>
                    <h4>Your donation of £{subscription.pounds} will get you:</h4>
                    <Instructions user={user}/>
                </> : <>
                    <h1>Your subscription expired</h1>
                    <p>Your last payment went through over a month ago. This could be because your card has expired, or your payment failed to go through.</p>
                </>}
                
                {subscription.manual?null:<div className="leftButtons">
                    <Button
                        onClick={() => {
                            Modals.create(EditSub, {
                                pounds: subscription.pounds,
                                callback: async pounds => {
                                    user.sub = { pounds, expires: subscription.expires };
                                    setSubscription(user.sub);
                                    setUser(JSON.parse(JSON.stringify(user)));
                                }
                            })
                        }}
                    >
                        Edit donation
                    </Button>
                    <Button
                        onClick={async () => {
                            if (await areYouSure('Are you sure you want to cancel your monthly donation?')) {
                                delete user.sub;
                                setSubscription(user.sub);
                                setUser(JSON.parse(JSON.stringify(user)));
                                await axios.post('/api/delete-sub', { user });
                            }
                        }}
                    >
                        Cancel subscription
                    </Button>
                </div>}
            </div>
        }
    </div>
}