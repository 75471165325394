import React, { useContext, useEffect, useState } from 'react';
import Pages from '../posts/Pages'
import { ResponsiveContext, AccountContext, useChange, HistoryContext } from '../../hooks';

import ShareButtons from '../posts/ShareButtons';
import Button from '../controls/Button';
import Info from '../controls/Info';

export default function TextFeed({ page, route }) {
    let { desktop } = useContext(ResponsiveContext);
    let { user } = useContext(AccountContext);
    let { noNavigate } = useContext(HistoryContext);

    return <div className="Panel MainFeed centrepanel">
        {desktop ? <ShareButtons heading={false} />:null}
        <Pages
            query={{$or:[{type:'Blog'},{type:'Poll'}]}}
            startingPage={page}
            url={`/`}
        />
    </div>
}