import React, {useState, useContext, useRef, useEffect } from 'react';
import { useSubmit, ToastContext, useGetState } from '../../hooks';
import Input from '../controls/Input';
import Number from '../controls/Number';
import Button from '../controls/Button';
import ModalHeading from '../modal/ModalHeading';
import Toast from '../modal/Toast';
import Btn from '../controls/Btn';
import { ImgCross } from '../../pictures/SVG';
import Shrinker from '../controls/Shrinker';
import Pounds from '../checkout/Pounds';
import { dateString } from '../../functions';

let validateQuestion = question => {
    if (question.length == 0) return 'Please provide answer question!';
    if (question.length < 6) return 'Question too short!';
    if (question.length > 5000) return 'Question too long!';
}

let validateAnswer = answer => {
    if (answer.length > 50) return 'Answer too long!';
    if (answer.length < 1) return 'Answer too short!';
}

export default function StartPoll({ close, post, callback, feed }) {
    let Toasts = useContext(ToastContext);

    let [c, submit, onSubmit] = useSubmit();
    let [loading, setLoading] = useState(false);

    let [question, setQuestion] = useState(post ? post.question : '');
    let [answers, setAnswers] = useState(post ? post.answers.map(answer => answer.answer) : []);
    let [days, setDays] = useState(post ? post.days : 7);

    let [questionError, setQuestionError] = useState('');
    let [answersError, setAnswersError] = useState('');

    let [serverError, setServerError] = useState('');

    useEffect(() => {
        console.log({answersError})
    },[answersError])

    onSubmit(async () => {
        if (!questionError && answers.length > 1 && !answersError) {
            setLoading(true);
            if (!post) {
                post = {};
                post.answers = answers;
                post.days = days;
            }
            post.question = question;

            let res = await callback(post);
            if (res) {
                close();
            } else {
                Toasts.create(Toast, { type: "error", preset: 'server error' });
                setServerError('Server error!');
            }
            setLoading(false);
        }
    })

    return <div className="StartPoll modal">
        <ModalHeading title="Start poll" close={close} />
        <div className="body">
            <form onSubmit={submit}>
                <Input
                    label="Question"
                    value={question}
                    setValue={setQuestion}
                    error={questionError}
                    setError={setQuestionError}
                    validation={validateQuestion}
                    attempts={c}
                    disabled={loading}
                />

                
                {post ? <>
                    <label>Answers</label>
                    <ol>{answers.map((a, i) => <li key={i}>{a}</li>)}</ol>
                    <label>Closes on {dateString(post.deathday)}</label>
                    <br />
                </> : <>
                    <InputAnswers loading={loading} setAnswers={setAnswers} setAnswersError={setAnswersError} c={c} />
                    <Number
                        label="Close after"
                        suffix="days"
                        value={days}
                        setValue={setDays}
                        min={1}
                        max={31}
                        disabled={loading}
                    />
                </>}

                <br />

                <div className="formButtons">
                    <div className="buttons">
                        <Button submit loading={loading}>Submit</Button>
                        {feed=='Sketches'?<div className="note">* This will be posted among the sketches.</div>:null}
                    </div>
                    <div className="error">
                        {serverError}
                    </div>
                </div>
            </form>
        </div>
    </div>
}

function Cross({visible, onClick}) {
    return <div className={`cross ${!visible ? 'invisible' : ''}`}><Btn onClick={onClick} disabled={!visible}>
        <ImgCross/>
    </Btn></div>
}

function Answer({ value, setValue, label, attempts, disabled }) {
    let [error, setError] = useState('');
    return <Input
        value={value}
        disabled={disabled}
        setValue={setValue}
        label={label}
        littleLabel
        attempts={attempts}
        validation={validateAnswer}
        error={error}
        setError={setError}
    />
}

function InputAnswers({ setAnswers: setAnswers0, setAnswersError, c, loading }) {
    let [answers, setAnswers] = useState([{ answer: '', expanded: true, key:-2 }, { answer: '', expanded: true, key:-1 }]);
    let k = useRef(0);

    useEffect(() => {
        setAnswers0(answers.map(a => a.answer));
        setAnswersError(!answers.map(a => !validateAnswer(a.answer)).reduce((a, b) => a && b, true));
    },[answers])

    return <div className="answersInput">
        <label>Answers</label>
        {answers.map(({answer, expanded, key}, i) => <Shrinker animateOn expanded={expanded} key={key}>
            <div className="answer" >
                <div className="mid"><Answer
                    value={answer}
                    setValue={answer => {
                        answers[i].answer = answer;
                        setAnswers(answers.slice());
                    }}
                    label={'Option ' + (i + 1)}
                    disabled={loading}
                    attempts={c}
                /></div>
                <div className="right">
                    <Cross onClick={() => {
                        answers[i].expanded = false;
                        setAnswers(answers.slice());
                        setTimeout(() => {
                            setAnswers(answers => {
                                answers.splice(i, 1);
                                return answers.slice();
                            })
                        }, 300)
                    }} visible={answers.length > 2} />
                </div>
            </div>
        </Shrinker>)}
        <div className="centreFlex">
            <Btn onClick={() => setAnswers([...answers, {answer: '', expanded: true, key: k.current++}])}>Add option</Btn>
        </div>
    </div>
}