import React, { useContext, useEffect, useRef, useState } from 'react';
import UserPanel from '../user/UserPanel';
import { dateString, pluralise } from '../../functions';
import { Countdown } from '../controls/Countdown';
import Button from '../controls/Button';
import { AccountContext, HistoryContext, ModalContext, NotificationsContext, ResponsiveContext, ToastContext, useAreYouSure, useAsync, useForceRender, useTimeout, useTitle } from '../../hooks';
import UploadAmbigram from '../forms/UploadAmbigram';
import axios from 'axios';
import Shrinker from '../controls/Shrinker';
import Ambigram from '../ambigram/Ambigram';
import P from '../controls/P';
import Btn from '../controls/Btn';
import Toast from '../modal/Toast';
import { ImgDropdown, ImgDropup, ImgLeft, ImgRight } from '../../pictures/SVG';
import Username from '../user/Username';
import A from '../controls/A';
import DuelEnlisting from '../modal/DuelEnlisting';
import AmbigramPanel from '../ambigram/AmbigramPanel';
import Spinner from '../Logo/Spinner';
import { NavLink } from 'react-router-dom/cjs/react-router-dom.min';

let cuteNames = ['wordplay wizards','brave battlers','glyph gurus','devilish designers','wicked wordsmiths','daring duelists','gallant gladiators','mighty magicians','valiant visionaries','fearless fighters','fickle font fiends','letter-bending legends','script scholars','rune wranglers']
let cuteName = (id) => cuteNames[parseInt(id.substr(id.length - 3, 3), 16) % cuteNames.length]

function ClosedDuel({ post }) {
    let { user, setUser } = useContext(AccountContext);
    let [duelist, setDuelist] = useState(user ? user.duelist : null);
    let Modals = useContext(ModalContext);
    let Toasts = useContext(ToastContext);
    let areYouSure = useAreYouSure();
    let [width, setWidth] = useState(600);
    let { device, bigPhone } = useContext(ResponsiveContext);
    
    useEffect(() => {
        if (device == 'phone') {
            setWidth(400);
        } else if (device == 'tablet') {
            setWidth(500);
        } else setWidth(600);
    }, [device])

    return <div className="Duel Panel centrepanel" >
        {
            post ? <div className="prevNext">
                <div>{post.prev ? <NavLink to={post.prev.url}><ImgLeft/>Duel #{post.count - 1}: {post.prev.word}</NavLink> : null}</div>
                <div>{post.next ? <NavLink to={post.next.url}>Duel #{post.count + 1}: {post.next.word}<ImgRight/></NavLink> : null}</div>
            </div>:null
        }
        <h1 className="lilmb">Duel arena - <span>{post.word}</span></h1>
        <h4 className="lilmb">Brought to you by <Username username={post.participants[0].username} /> and <Username username={post.participants[1].username} />!</h4>
        <p>Our {cuteName(post._id)} were tasked with making their own ambigram of <b>"{post.word}."</b></p>
        {user && user.curated ? duelist ? <>
            <p>Thank you for enlisting with the duel arena. If you do not wish to partake in any future duels, <A blue onClick={async () => {
                if (await areYouSure('Are you sure you want to unenlist?')) {
                    await axios.post('/api/unenlist-in-duels', { user });
                    setUser({ ...user, duelist: false });
                    setDuelist(false);
                    Toasts.create(Toast, { text: `You have been unenlisted!` });   
                }
            }}>click here.</A></p>
        </> : <>
            <p><A blue onClick={() => Modals.create(DuelEnlisting, { callback:()=>setDuelist(true)})}>Click here if you wish to partake in a future duel!</A></p>
        </> : null}
        <Participant participant={post.participants[0]} width={width}/>
        <Participant participant={post.participants[1]} width={width}/>
    </div>
}

function Participant({ participant, width }) {
    let [expanded, setExpanded] = useState(false);
    return <div className="Post participant">
            <div className="postLeft">
                <UserPanel post={participant.entry}/>
            </div>
            <div className="postRight">
                <Shrinker expanded={expanded}><div className="mb">
                    <h4 className="lilmb">{pluralise(participant.username)} progress pictures</h4>
                    {participant.progress.map((p, i) => <div className="winner" key={i}>
                        <div className="progressAmbigram">
                            <Ambigram picture={p.picture} style={p.style} bounds={width} />
                            {p.message?<div className="message mt">
                                <P noMarginBottom>{p.message}</P>
                            </div>:null}
                        </div>
                    </div>)}
                </div></Shrinker>
                <h4 className="lilmb">{pluralise(participant.username)} final submission</h4>
                <AmbigramPanel post={participant.entry} contest3 noWordBit />
                
                {participant.progress.length > 0 ? <div className="progress">
                    <Shrinker expanded={!expanded}><Btn onClick={() => setExpanded(true)} className="expandProgress">
                        <div>Click here to view progress pictures</div>
                    </Btn></Shrinker>
                </div> : null}
            </div>
        </div>
}

function OpenDuel({ post }) {
    let Modals = useContext(ModalContext);
    let [ended, setEnded] = useState(() => post.deathday < new Date().getTime());
    let { user } = useContext(AccountContext);
    let { device } = useContext(ResponsiveContext);
    let [width, setWidth] = useState(600);
    let areYouSure = useAreYouSure();
    let [force, forceRender] = useForceRender();
    let { doTimeout } = useTimeout();
    let Toasts = useContext(ToastContext);
    let { forceNavigate } = useContext(HistoryContext);
    
    useEffect(() => {
        if (!post.progress) {
            forceNavigate('/duel-arena')
        }
    }, [post])

    useEffect(() => {
        if (device == 'phone') {
            setWidth(400);
        } else if (device == 'tablet') {
            setWidth(500);
        } else setWidth(600);
    }, [device])

    function uploadAmbigram(progress) {
        Modals.create(UploadAmbigram, {
            callback: async entry => {
                let {data} = await axios.post(progress?'/api/submit-duel-progress':'/api/submit-duel-entry', {...entry, post:{ _id: post._id }, user});
                if (data) {
                    data.entry.new = true;
                    if (progress) {
                        post.progress.push(data.entry);
                    } else {
                        post.entry = data.entry;
                    }
                    return true;
                } else {
                    return false;
                }
            },
            duel: true,
            noGlyphs: progress
        })
    }

    async function editEntry(i) {
        Modals.create(UploadAmbigram, {
            post: i<0?post.entry:post.progress[i],
            callback: async entry => {
                delete entry.new;
                let {data} = await axios.post(i<0?'/api/edit-duel-entry':'/api/edit-duel-progress', { post:{ _id: post._id }, entry, user });
                if (data) {
                    if (data.error) {
                        console.log(data.error)
                    } else {
                        if (i < 0) {
                            post.entry = entry;
                        } else {
                            post.progress[i] = entry;
                        }
                        forceRender();
                        return true;
                    }
                } else {
                    return false;
                }
            },
            duel: true,
            noGlyphs: i >= 0
        })
    }

    async function deleteEntry(i) {
        if (await areYouSure(`Are you sure you want to remove ${i<0?'your entry':'this progress picture'}?`)) {
            let { data } = await axios.post(i<0?'/api/remove-duel-entry':'/api/remove-duel-progress', { post:{ _id: post._id }, entry: i<0?post.entry:post.progress[i], user });
            if (data) {
                if (i < 0) {
                    post.entry.shrink = true;
                } else post.progress[i].shrink = true;
                forceRender();
                doTimeout(() => {
                    if (i < 0) {
                        delete post.entry;
                    } else post.progress.splice(i, 1);
                    forceRender();
                }, 0.35, 's')
            } else {
                Toasts.create(Toast, { preset: "server error" });
            }
        }
    }

    let [animating, setAnimating] = useState(false);
    let [tops, setTops] = useState([]);
    let refs = useRef([]);

    function swap(i,j) {
        let d = refs.current[j].getBoundingClientRect().top - refs.current[i].getBoundingClientRect().top;
        let tops = post.progress.map((e, k) => k == i ? d : k == j ? -d : 0);
        setTops(tops.map(t => 0));
        setTimeout(() => {
            setAnimating(true);
            setTimeout(() => {
                setTops(tops);
                doTimeout(() => {
                    let tmp = post.progress[i];
                    post.progress[i] = post.progress[j];
                    post.progress[j] = tmp;
                    setAnimating(false);
                    axios.post('/api/swap-duel-progress', { user, post:{ _id: post._id }, i, j });
                },300)
            },20)
        },1)
    }

    if (!post.progress) return null;

    return <div className="Duel Panel centrepanel">
        {
            post ? <div className="prevNext">
                <div>{post.prev ? <NavLink to={post.prev.url}><ImgLeft/>Duel #{post.count - 1}: {post.prev.word}</NavLink> : null}</div>
                <div>{post.next ? <NavLink to={post.next.url}>Duel #{post.count + 1}: {post.next.word}<ImgRight/></NavLink> : null}</div>
            </div>:null
        }
        <h1 className="lilmb">Your duel - <span>{post.word}</span></h1>
        {ended?<h4>The submission period is over!</h4>:<h4>Deadline: {dateString(post.deathday)} 13:00 GMT</h4>}
        <Countdown time={post.deathday} setEnded={setEnded} />
        <h4 className="mb">You may submit 3 progress pictures, and 1 final design!</h4>

        <div className="PANEL mb">
            <h2 className="lilmb">Submit your entry</h2>
            <Shrinker expanded={!post.entry}>
                <p>Please submit your finished design with the button below.</p>
                <p>Once you've submitted a finished design, you'll be given the option to submit progress pictures.</p>
            </Shrinker>
            
            {post.entry?<Shrinker expanded={!post.entry.shrink} animateOn={post.entry.new}>
                <div className="entry">
                    <div className="entryLeft">
                        <Ambigram picture={post.entry.picture} style={post.entry.style} bounds={width} />
                    </div>
                    <div className="entryRight">
                        <div>
                            <P>{post.entry.message}</P>
                            {!ended ? <>
                                <Btn onClick={() => editEntry(-1)}>Edit</Btn>
                                <Btn colour="red" onClick={() => deleteEntry(-1)}>Delete</Btn>
                            </> : null}
                        </div>
                    </div>
                </div>
            </Shrinker>:null}
            
            <div className="buttonsGap">
                <Button
                    onClick={()=>uploadAmbigram(false)}
                    disabled={post.entry || ended}
                >
                    Submit your finished ambigram
                </Button>
            </div>
        </div>

        {post.entry || post.progress.length > 0 ? <div className="PANEL">
            <div className="entries">
                <h2 className="lilmb">Progress pictures</h2>
                <Shrinker expanded={post.progress.length > 1}>
                    <p>You may submit up to 3 progress pictures. Please include a message with each one, detailing all the creative decisions you made!</p>
                    <p className="lilSvgs">You may rearrange them with the up ({<ImgDropup />}) and down ({<ImgDropdown />}) buttons to the left of each picture.</p>
                </Shrinker>

                {post.progress.map((entry,i) => <div key={entry.picture.path} ref={r=>refs.current[i] = r} className={`movingBox ${animating?'animating':''}`} style={animating?{top:tops[i]}:null}><Shrinker  expanded={!entry.shrink} animateOn={entry.new}>
                    <div className="entry">
                        <div className="entryLeftLeft">
                            <Btn disabled={i <= 0 || ended} onClick={() => swap(i-1,i)} className="arrowButton">
                                <ImgDropup />
                            </Btn>
                            <Btn disabled={i >= post.progress.length - 1 || ended} onClick={() => swap(i,i+1)} className="arrowButton">
                                <ImgDropdown/>
                            </Btn>
                        </div>
                        <div className="entryLeft">
                            <Ambigram picture={entry.picture} style={entry.style} bounds={width} />
                        </div>
                        <div className="entryRight">
                            <div>
                                <P>{entry.message}</P>
                                {!ended ? <>
                                    <Btn onClick={() => editEntry(i)}>Edit</Btn>
                                    <Btn colour="red" onClick={() => deleteEntry(i)}>Delete</Btn>
                                </> : null}
                            </div>
                        </div>
                    </div>
                </Shrinker></div>)}


                <div className="buttonsGap">
                    <Button
                        onClick={()=>uploadAmbigram(true)}
                        disabled={post.progress.length >= 3 || ended}
                    >
                        Submit a progress picture
                    </Button>
                </div>
            </div>
        </div> : null}
    </div>
}

export default function Duel({ url, post:post0 }) {
    let {user} = useContext(AccountContext);
    let [post, setPost] = useState(post0);
    let { getNotifications, setNotifications } = useContext(NotificationsContext);
    let { setDocumentTitle } = useTitle();

    useEffect(() => {
        if(post) setDocumentTitle(`${post.word} duel - $`);
    }, [post])

    useAsync(async () => {
        if (!post) {
            let { data } = await axios.post('/api/duel', { url, user });
            setPost(data.post);
            console.log(data.post);
        }
    }, [user])

    useAsync(async () => {
        if (user && post) {
            let notifications = getNotifications();
            let i = notifications.findIndex(n => n.url == post.url);
            if (i >= 0) {
                axios.post('/api/seen', { url: post.url, user });
                notifications.splice(i, 1);
                setNotifications(notifications.slice());
            }
        }
    }, [user, post])

    if (!post) return <div className="Duel Panel centrepanel"><div className="centreFlex">
        <Spinner/>
    </div></div>;
    if (post.open) {
        return <OpenDuel post={post} />
    } else if (post.closed) {
        return <ClosedDuel post={post} />
    } else return null;
}