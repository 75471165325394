import React, { useState, useContext } from 'react';
import AmbigramPost from './AmbigramPost';
import BlogPost from './BlogPost';
import Button from '../controls/Button';
import Shrinker from '../controls/Shrinker';
import Loader from '../controls/Loader';
import Toast from '../modal/Toast';
import { AccountContext, ToastContext, CreateDynamic, NotificationsContext, ModalContext } from '../../hooks';

import {ImgPointdown} from'../../pictures/SVG';
import axios from 'axios';
import Ambigram from '../ambigram/Ambigram';
import Username from '../user/Username';
import { NavLink } from 'react-router-dom';
import A from '../controls/A';
import ModalMessage from '../modal/ModalMessage';
import ChallengePost from './ChallengePost';
import PollPost from './PollPost';

function BigPost({ post: startingPost, hidden, single, page, contest3 }) {
    let [deleted, SetDeleted] = useState(false);
    let [hide, SetHidden] = useState(false);
    let { user } = useContext(AccountContext);
    let Toasts = useContext(ToastContext);
    let Modals = useContext(ModalContext);
    let [buttonLoading, setButtonLoading] = useState(false);
    let [loading, SetLoading] = useState(false);
    let [post, SetPost] = useState(startingPost);
    let { getNotifications, setNotifications } = useContext(NotificationsContext);

    let DC = CreateDynamic('post', post._id);

    let Render = null;
    if (post.type == 'Ambigram' || post.type == 'Entry' || post.type == 'ChallengeEntry' || post.type == 'DuelEntry') {
        Render = <AmbigramPost post={post} contest3={contest3} />
    } else if (post.type == 'Challenge') {
        Render = <ChallengePost post={post} single={single} page={page} />
    } else if (post.type == 'Blog') {
        Render = <BlogPost post={post} />
    } else if (post.type == 'Poll') {
        Render = <PollPost post={post} />
    }

    function onMouseEnter() {
        if (user) {
            let notifications = getNotifications();
            let i = notifications.findIndex(n => n.url == post.url);
            if (i >= 0) {
                axios.post('/api/seen', { url: post.url, user });
                notifications.splice(i, 1);
                setNotifications(notifications.slice());
            }
        }
    }

    return <DC.Provider value={{SetDeleted, SetLoading, SetPost, SetHidden}}>
        <Loader loading={loading} style="darkgrey">
            <Shrinker expanded={!deleted && !hide} unrender>
                <div className="postHeadingHeading" onMouseEnter={onMouseEnter}>
                    {hidden ?
                        <Shrinker expanded={true} animateOn>
                            <div className="postHeading"><ImgPointdown />
                                <div>
                                    This post is not visible to others. <A onClick={() => {
                                        let { visFeed, visGlyphs, visProfile } = post;
                                        let body = ''
                                        if (!visFeed && !visGlyphs && !visProfile) {
                                            body = `The visibility options on this post are set such that it is visible only to you.`;
                                        } else {
                                            let array = [{ string: "on the main feed", value: visFeed }, { string: "through the glyph search feature", value: visGlyphs }, { string: "on your profile", value: visProfile }]
                                            let allowed = array.filter(a => a.value).map(b=>b.string);
                                            let notAllowed = array.filter(a => !a.value).map(b => b.string);
                                            if (allowed.length > 1) allowed[allowed.length - 1] = 'and ' + allowed[allowed.length - 1];
                                            if (notAllowed.length > 1) notAllowed[notAllowed.length - 1] = 'or ' + notAllowed[notAllowed.length - 1];
                                            for (let i = 0; i < allowed.length - 2; i++) allowed[i] = allowed[i] + ',';
                                            for (let i = 0; i < notAllowed.length - 2; i++) notAllowed[i] = notAllowed[i] + ',';
                                            body = `The visibility options on this post are set such that it is visible only ${allowed.join(' ')}. It is not visible ${notAllowed.join(' ')} - only you can see it here!`
                                        }
                                        body += '\nYou may change the visibility options by editing this post.'
                                        Modals.create(ModalMessage, { title: 'Post visibility', body })
                                    }}>(What does this mean?)</A>
                                </div>
                                <ImgPointdown />
                            </div>
                        </Shrinker>
                    : null}
                    {Render}
                </div>
            </Shrinker>
        </Loader>

        <Shrinker expanded={deleted} unrender>
        <div className="deleted">
            <h2 className={buttonLoading?"grey":null}>Post deleted!</h2>
                <Button colour="red" loading={buttonLoading} onClick={async () => {
                    setButtonLoading(true);
                    let {data} = await axios.post('/api/undelete-post', { post, user });
                    if (data) {
                        SetDeleted(false);
                    } else {
                        Toasts.create(Toast, {preset:'server error'});
                    }
                    setButtonLoading(false);
                }}>Undo</Button>
        </div>
        </Shrinker>

    </DC.Provider>
}

function MiniPost({ post }) {
    return <div className="miniPost PANEL">
        <Ambigram picture={post.picture} style={post.style} />
        <div className="miniCaption"><NavLink to={post.url}>{post.word}</NavLink> by <Username username={post.username} /></div>
    </div>
}

export default function Post({ post, hidden, mini, single, page, contest3 }) {
    if (!mini) {
        return <BigPost post={post} hidden={hidden} single={single} page={page} contest3={contest3} />
    } else {
        return <MiniPost post={post}/>
    }
}